import React from 'react';
import {Button, Form, Input, message, Modal} from 'antd';
import {FormInstance} from "antd/lib/form";
import {api} from "../../../service/apiClient";
import {finalize} from 'rxjs/operators';
import {BulkConfigureProps} from "../device/BulkActionButtons/BulkSetReadIntervalButton";

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

type ForgotPasswordFormProps = {
    modal: ForgotPasswordModal,
    email: string
}

const formRef = React.createRef<FormInstance>(); // Link form submit event to modal button
const ForgotPasswordForm = ({modal, email}: ForgotPasswordFormProps) => {
    const [form] = Form.useForm();

    // On valid form submission
    const onFinish = (values: any) => {
        modal.setState({confirmLoading: true});
        // TODO - test with no server running
        api.resetPassword(values.email)
            .pipe(finalize(() => modal.setState({confirmLoading: false})))
            .subscribe((created: boolean) => {
                if (created) {
                    modal.handleClose()
                    formRef.current!.resetFields();
                    message.success("Password reset")
                } else {
                    message.error("Password reset denied")
                }
            }, error => {
                message.error(error)
            })
    };

    return (
        <Form
            {...formItemLayout}
            form={form}
            name="Reset Password"
            onFinish={onFinish}
            scrollToFirstError
            ref={formRef}
            initialValues={{ email: email }} // Set initial values here
        >
            <Form.Item
                name="email"
                label="E-mail"
                rules={[
                    {
                        type: 'email',
                        message: 'Invalid E-mail',
                    },
                    {
                        required: true,
                        message: 'Please input your E-mail',
                    },
                ]}
            >
                <Input/>
            </Form.Item>
        </Form>
    );
}

interface ForgotPasswordModalProps {
    email: string;
    isOpen: boolean;
    onClose: () => void;
    onOpenClick: () => void;
}

class ForgotPasswordModal extends React.Component<ForgotPasswordModalProps> {


    state = {
        confirmLoading: false
    };

    handleOk = () => {
        formRef.current!.submit();
    };

    handleClose = () => {
        this.props.onClose();
    }

    handleCancel = () => {
        formRef.current!.resetFields();
        this.props.onClose();
        this.setState({
            confirmLoading: false
        });
    };

    render() {
        const {isOpen } = this.props;
        const {confirmLoading} = this.state;
        return (
            <Button type="link" style={{paddingRight:0, marginTop:0, height:20}} onClickCapture={this.props.onOpenClick} size={"small"}>
                Reset Password
                <Modal
                    title="Reset Password"
                    visible={isOpen}
                    onOk={this.handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={confirmLoading} onClick={this.handleOk}>
                            Submit
                        </Button>,
                    ]}
                >
                    <ForgotPasswordForm modal={this} email={this.props.email}/>
                </Modal>
            </Button>
        );
    }
}

export default ForgotPasswordModal;