import React, {useEffect, useState} from 'react';
import {Alert, Button, Col, Divider, Drawer, Form, Input, Layout, List, message, Row, Spin} from 'antd';
import CreateUserModal from "./createUser"
import {ArrowLeftOutlined, LockOutlined, QuestionCircleOutlined, UserOutlined} from '@ant-design/icons';
import {session} from "../../../service/session";
import {launchApp} from "../../../index";
import ForgotPasswordModal from "./forgotPassword";
import {AuthenticationResult} from './authenticationResult';
import {CredentialResponse, GoogleLogin} from "@react-oauth/google";
import {useMsal} from "@azure/msal-react";
import {ReactComponent as MicrosoftIcon} from "../../../assets/ms-symbollockup_mssymbol_19.svg";

const {Content, Footer} = Layout;


const contentStyles = {
    backgroundColor: "white",
    height: "calc(100vh - 70px)",
    fontSize: "1.6em"
}

const itemWidths = 275;

const FAQS = [
    {
        title: 'What is the Geokon Open API?',
        description: "The API is a defined set of standard queries that any customer can use to easily retrieve sensor data from" +
            " any of Geokon's connected products."
    },
    {
        title: 'What is this site?',
        description: "This is a user interface to assist in using the API."+
            " A user can perform basic functions such as registering Geokon devices, billing setup, and controlling data access from other platforms."
    },
    {
        title: 'Do I need to create a user?',
        description: "No, although it is recommended. All functionality available through this portal is exposed directly " +
            "through the API itself; however, even the most advanced software integrators will likely benefit from using this interface."
    },
    {
        title: 'Is there a cost to signing up?',
        description: "No, creating a user is free and there are no ongoing costs. However, to activate and connect"
            + " devices, you will be required to enter payment information for any associated charges."
    },
]

const LoginPage = () => {

    // Show alert for invalid password
    const [loginStatus, setLoginStatus] = React.useState(AuthenticationResult.PENDING)
    const [disableLoginButton, setDisableLoginButton] = React.useState(false)
    const [loading, setLoading] = useState(false);
    const [usingPasswordSignIn, setUsingPasswordSignIn] = useState(false);
    const [loginForm] = Form.useForm();
    const {instance } = useMsal();
    const [openResetPassword, setOpenResetPassword] = useState(false);
    const passwordDisabled = process.env.REACT_APP_DISABLE_PASSWORD === "true"
    const usingMicrosoft = process.env.REACT_APP_MICROSOFT_CLIENT_ID !== undefined && (process.env.REACT_APP_MICROSOFT_CLIENT_ID as string).length !== 0;
    const usingGoogle = process.env.REACT_APP_GOOGLE_CLIENT_ID !== undefined && (process.env.REACT_APP_GOOGLE_CLIENT_ID as string).length !== 0;

    const InvalidPasswordAlert = () => {
        return (
            <>
                {loginStatus !== AuthenticationResult.PENDING && loginStatus !== AuthenticationResult.SUCCESS ? <Form.Item><Alert message={loginStatus} type={"error"} showIcon={true} onClose={() => setLoginStatus(AuthenticationResult.PENDING)} closable={true}/></Form.Item> : null}
            </>
        )
    }

    const handleFormSubmit: React.FormEventHandler<HTMLFormElement> = (e)  => {
        e.preventDefault(); // Prevent the default form submission

        // Check if we're not yet using password sign-in
        if (!usingPasswordSignIn) {
            loginForm.validateFields(["email"])
                .then((value) => {
                    e.stopPropagation()
                    setUsingPasswordSignIn(true); // Change the state to show the password input
                })
                .catch(() => {})
        } else {
            loginForm.submit();
        }
    };

    const onFinish = (values: any) => {
        setDisableLoginButton(true)
        setTimeout(function(){setDisableLoginButton(false)},5000) // hard timeout of 5 seconds to open log in button
        setLoginStatus(AuthenticationResult.PENDING)
        session.authenticateNewSession(values.email, values.password, status => {
            if (status === AuthenticationResult.SUCCESS) {
                setDisableLoginButton(false)
                launchApp()
            } else if (status === AuthenticationResult.RESET_PASSWORD_REQUIRED){
                setOpenResetPassword(true)
                setLoginStatus(status)
            } else {
                setDisableLoginButton(false)
                setLoginStatus(status)
            }
        })
    };

    const [helpVisible, setHelpVisible] = useState(false);

    const onFinishFailed = (errorInfo: any) => {
        setDisableLoginButton(false)
        setLoginStatus(AuthenticationResult.PENDING)
    };

    const handleGoogleSSO = (token: CredentialResponse) => {
        setLoading(true)
        let credential = token.credential

        if (credential === undefined) {
            message.error("Failed to login using Google")
        } else {

            setDisableLoginButton(true)
            setTimeout(function(){setDisableLoginButton(false)},5000) // hard timeout of 5 seconds to open log in button
            setLoginStatus(AuthenticationResult.PENDING)
            session.authenticateNewSessionSSO(credential, "google", status => {
                setLoading(false)
                if (status === AuthenticationResult.SUCCESS) {
                    setDisableLoginButton(false)
                    launchApp()
                } else {
                    setDisableLoginButton(false)
                    setLoginStatus(status)
                }
            })
        }
    }

    const handleMicrosoftSSO = () => {
        setLoading(true)
        instance.loginPopup({
            scopes: ["openid", "profile", "email"],
        }).then((response) => {
            const token = response.idToken;

            setDisableLoginButton(true)
            setTimeout(function(){setDisableLoginButton(false)},5000) // hard timeout of 5 seconds to open log in button
            setLoginStatus(AuthenticationResult.PENDING)
            session.authenticateNewSessionSSO(token, "microsoft", status => {
                setLoading(false)
                if (status === AuthenticationResult.SUCCESS) {
                    setDisableLoginButton(false)
                    launchApp()
                } else {
                    setDisableLoginButton(false)
                    setLoginStatus(status)
                }
            })
        }).catch((error) => {
            setLoading(false)
            setLoginStatus(AuthenticationResult.TIMEOUT)
        });
    }

    return (
        <Spin spinning={loading}>
        <Layout>

            <Content style={contentStyles}>
                <Row justify="center" align="middle" style={{minHeight: 'calc(100vh - 25%)', fontSize: "1.6em", justifyContent: 'center'}}>
                    <Col >

                        {/*LOGO*/}
                        <a href="https://www.geokon.com" target="_blank" rel="noreferrer">
                            <img style={{
                                maxWidth: (window.innerWidth > 500 ? '300px' : '180px'),
                                justifyContent: 'center',
                                paddingBottom: 25
                            }} src={'https://storage.googleapis.com/geokon-public-assets/logo.png'} className="App-logo"
                                 alt="logo"/>
                        </a>

                        {passwordDisabled ? null :
                            <Form style={{fontSize: "1.6em", width: itemWidths, justifyContent: 'center', margin: "auto"}}
                               name="basic"
                               form={loginForm}
                               onFinish={onFinish}
                               onFinishFailed={onFinishFailed}
                               onSubmitCapture={handleFormSubmit}
                            >
                                <Form.Item
                                    name="email"
                                    style={{fontSize: "1.6em"}}
                                    rules={[{required: true, message: 'Please enter email'}]}
                                >
                                    <Input
                                        prefix={<UserOutlined style={{paddingRight: 10}} className="site-form-item-icon"/>}
                                        placeholder={'Email'}
                                        style={{fontSize: "1.4em"}} onInput={() => setDisableLoginButton(false)}/>
                                </Form.Item>

                                {/*Password Field, forgot password, and create user. */}
                                {/*Should only appear after we click continue from the original screen*/}
                                {usingPasswordSignIn ?
                                    <>
                                        <Form.Item
                                            name="password"
                                            rules={[{required: usingPasswordSignIn, message: 'Please enter password'}]}
                                            style={{fontSize: "1.6em", marginBottom: "12px"}}
                                        >
                                            <Input.Password prefix={<LockOutlined style={{paddingRight: 10}}
                                                                                  className="site-form-item-icon"/>}
                                                            placeholder={'Password'}
                                                            style={{fontSize: "1.4em"}}
                                                            onInput={() => setDisableLoginButton(false)}/>

                                        </Form.Item>
                                        <Form.Item style={{marginBottom: "12px"}}>
                                            <Row style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <CreateUserModal email={loginForm.getFieldValue("email")}/>
                                                <ForgotPasswordModal isOpen={openResetPassword}
                                                                     onClose={() => setOpenResetPassword(false)}
                                                                     onOpenClick={() => setOpenResetPassword(true)}
                                                                     email={loginForm.getFieldValue("email")}/>
                                            </Row>
                                        </Form.Item>
                                    </> : null
                                }

                                <InvalidPasswordAlert/>

                                <Form.Item style={{fontSize: "1.6em"}}>
                                    {/*Continue/Login and back buttons*/}
                                    <Row>
                                        {usingPasswordSignIn && (
                                            <Button type="default" onClick={() => setUsingPasswordSignIn(false)}
                                                    size={"large"} icon={<ArrowLeftOutlined/>}
                                                    style={{
                                                        marginRight: 5, // Adding some space between the two buttons
                                                        width: 40, // Fixed width for the first button
                                                        backgroundColor: 'rgba(0, 129, 198, 1)',
                                                        color: "white"
                                                    }}
                                            />
                                        )}

                                        <Button type="default" disabled={disableLoginButton} htmlType="submit"
                                                size={"large"}
                                                style={{
                                                    flexGrow: 1,
                                                    backgroundColor: 'rgba(0, 129, 198, 1)',
                                                    color: "white"
                                                }}>
                                            {usingPasswordSignIn ? "Login" : "Continue"}
                                        </Button>
                                    </Row>

                                </Form.Item>
                            </Form>
                        }

                        {/*SSO Login Buttons*/}
                        {/*Should disappear if user clicks continue to enter a password*/}
                        {!usingPasswordSignIn ? <>
                            {(!passwordDisabled && (usingGoogle || usingMicrosoft)) ? <Divider style={{fontSize: "1em"}}>or</Divider> : null}
                            {usingGoogle ?
                                <Row style={{marginBottom: "12px"}} justify={"center"}>
                                    <GoogleLogin
                                        logo_alignment={"left"}
                                        text={"continue_with"}
                                        shape={"rectangular"}
                                        width={230}
                                        size={"large"}
                                        onSuccess={credentialResponse => {
                                            handleGoogleSSO(credentialResponse);
                                        }}
                                        onError={() => {
                                            setLoginStatus(AuthenticationResult.TIMEOUT)
                                        }}
                                    />
                                </Row> : null
                            }


                            {usingMicrosoft ?
                                <Row justify={"center"}>
                                    <Button
                                        icon={<MicrosoftIcon style={{marginRight: 10}}/>}
                                        onClick={handleMicrosoftSSO}
                                        style={{
                                            display: 'inline-flex',
                                            width: 230,
                                            height: 40,
                                            alignItems: 'center',
                                            borderRadius: 4,
                                            justifyContent: 'center' // This ensures horizontal alignment
                                        }}
                                    >
                                        <span style={{marginRight: 10, verticalAlign: 'middle', fontWeight: 500}}>Continue with Microsoft</span>
                                    </Button>

                                </Row> : null
                            }
                        </> : null}
                    </Col>
                </Row>

            </Content>

            <Footer style={{ textAlign: 'center', backgroundColor: 'white' }}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                    <span>{'Geokon ©' + new Date().getFullYear()}</span>
                    <div style={{margin: '0 10px', height: '15px', borderLeft: '1px solid black'}}></div>
                    <Button type="link" style={{padding: 0, height: 25}} onClick={() => {setHelpVisible(true)}}>API Documentation</Button>
                </div>
            </Footer>

            {/*ABOUT SECTION*/}
            <Drawer
                placement="left"
                closable={true}
                visible={helpVisible}
                width={'90%'}
                onClose={() => {setHelpVisible(false)}}
            >
                <Row>
                    <Col span={22} offset={1}>
                        <Divider orientation="left" style={{paddingTop: '40px'}}>Getting started with the Open API</Divider>

                        <List itemLayout="horizontal"
                              dataSource={FAQS}
                              renderItem={item => (
                                  <List.Item>
                                      <List.Item.Meta
                                          avatar={<QuestionCircleOutlined style={{fontSize: '1em', verticalAlign: 'center'}}/>}
                                          title={item.title}
                                          description={item.description}
                                      />
                                  </List.Item>
                              )}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={22} offset={1}>
                        <Divider orientation="left" style={{paddingTop: '40px'}}>Using the API</Divider>

                        <a href="https://apidocs.geokon.com" target="_blank" rel="noreferrer" style={{fontSize: '1.5em', verticalAlign: "middle"}}>
                            Take me to the API documentation
                        </a>
                    </Col>

                </Row>

            </Drawer>

        </Layout>
        </Spin>
    );
};

export default LoginPage