import React, {useState} from 'react';
import {Menu} from 'antd';
import {ApiOutlined, CreditCardOutlined, DeploymentUnitOutlined,BarChartOutlined, LockFilled} from '@ant-design/icons';
import {useHistory} from 'react-router';
import {session} from "../../service/session";

const SideNav = () => {
    const history = useHistory();

    // Map routes to selected menu tab
    const getSelected = () => {
        const path = history.location.pathname
        if (path.startsWith('/connections')) {
            return ['connections']
        } else if (path.startsWith('/devices') || path.startsWith('/device')) {
            return ['devices']
        } else if (path.startsWith('/billing')) {
            return ['billing']
        } else if (path.startsWith('/admin')) {
            return ['admin']
        } else if (path.startsWith('/data')) {
            return ['data']
        } else {
            return []
        }
    }
    history.listen((event: any) => {
        setSelectedKeys(getSelected)
    })
    const [selectedKeys, setSelectedKeys] = useState(getSelected());

    return (
        <div>
            {session.isDarkSession() ?
                <div style={{height: "32px", margin: "16px"}}>
                    <a href="https://www.geokon.com" target="_blank" rel="noreferrer">
                        <img src={'https://storage.googleapis.com/geokon-public-assets/logo_dark.svg'} style={{width: '270px', marginTop: "-130px", marginLeft: "-55px"}}
                             alt="logo"/>
                    </a>
                </div>
                :
                <div style={{height: "32px", margin: "16px", zIndex: 99, position: "relative"}}>
                    <a href="https://www.geokon.com" target="_blank" rel="noreferrer">
                        <img style={{maxWidth: '260px'}}
                             src={'https://storage.googleapis.com/geokon-public-assets/logo.png'} className="App-logo"
                             alt="logo"/>
                    </a>
                </div>
            }

            <Menu theme="light" mode="inline" selectedKeys={selectedKeys}>
                <Menu.Item key="devices" onClick={() => history.push('/devices')}>
                    <DeploymentUnitOutlined />
                    <span> Devices</span>
                </Menu.Item>
                <Menu.Item key="connections" onClick={() => history.push('/connections')}>
                    <ApiOutlined />
                    <span> Connections</span>
                </Menu.Item>
                <Menu.Item key="data" onClick={() => history.push('/data')}>
                    <BarChartOutlined />
                    <span> Data</span>
                </Menu.Item>
                <Menu.Item key="billing" onClick={() => history.push('/billing')}>
                    <CreditCardOutlined />
                    <span> Billing</span>
                </Menu.Item>
                {
                    session.isAdminSession() ?
                        <Menu.Item key="admin" style={{color:"red"}} onClick={() => history.push('/admin')}>
                            <LockFilled />
                            <span> Admin</span>
                        </Menu.Item> :
                    null
                }
            </Menu>
        </div>
    );
}

export default SideNav;